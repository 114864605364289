exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-art-js": () => import("./../../../src/pages/art.js" /* webpackChunkName: "component---src-pages-art-js" */),
  "component---src-pages-art-sale-js": () => import("./../../../src/pages/art-sale.js" /* webpackChunkName: "component---src-pages-art-sale-js" */),
  "component---src-pages-design-js": () => import("./../../../src/pages/design.js" /* webpackChunkName: "component---src-pages-design-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-printer-detail-js": () => import("./../../../src/pages/printer-detail.js" /* webpackChunkName: "component---src-pages-printer-detail-js" */),
  "component---src-pages-printer-howto-js": () => import("./../../../src/pages/printer-howto.js" /* webpackChunkName: "component---src-pages-printer-howto-js" */),
  "component---src-pages-printer-minted-js": () => import("./../../../src/pages/printer-minted.js" /* webpackChunkName: "component---src-pages-printer-minted-js" */),
  "component---src-pages-printer-search-js": () => import("./../../../src/pages/printer-search.js" /* webpackChunkName: "component---src-pages-printer-search-js" */),
  "component---src-pages-printer-submit-js": () => import("./../../../src/pages/printer-submit.js" /* webpackChunkName: "component---src-pages-printer-submit-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-roles-admin-approval-js": () => import("./../../../src/pages/roles/admin/approval.js" /* webpackChunkName: "component---src-pages-roles-admin-approval-js" */),
  "component---src-pages-roles-admin-index-js": () => import("./../../../src/pages/roles/admin/index.js" /* webpackChunkName: "component---src-pages-roles-admin-index-js" */),
  "component---src-pages-roles-admin-invite-js": () => import("./../../../src/pages/roles/admin/invite.js" /* webpackChunkName: "component---src-pages-roles-admin-invite-js" */),
  "component---src-pages-roles-admin-newapp-js": () => import("./../../../src/pages/roles/admin/newapp.js" /* webpackChunkName: "component---src-pages-roles-admin-newapp-js" */),
  "component---src-pages-roles-admin-newph-js": () => import("./../../../src/pages/roles/admin/newph.js" /* webpackChunkName: "component---src-pages-roles-admin-newph-js" */),
  "component---src-pages-roles-admin-settings-js": () => import("./../../../src/pages/roles/admin/settings.js" /* webpackChunkName: "component---src-pages-roles-admin-settings-js" */),
  "component---src-pages-roles-approval-index-js": () => import("./../../../src/pages/roles/approval/index.js" /* webpackChunkName: "component---src-pages-roles-approval-index-js" */),
  "component---src-pages-roles-approval-new-js": () => import("./../../../src/pages/roles/approval/new.js" /* webpackChunkName: "component---src-pages-roles-approval-new-js" */),
  "component---src-pages-roles-approval-queue-js": () => import("./../../../src/pages/roles/approval/queue.js" /* webpackChunkName: "component---src-pages-roles-approval-queue-js" */),
  "component---src-pages-roles-approval-settings-js": () => import("./../../../src/pages/roles/approval/settings.js" /* webpackChunkName: "component---src-pages-roles-approval-settings-js" */),
  "component---src-pages-roles-artist-collection-js": () => import("./../../../src/pages/roles/artist/collection.js" /* webpackChunkName: "component---src-pages-roles-artist-collection-js" */),
  "component---src-pages-roles-artist-index-js": () => import("./../../../src/pages/roles/artist/index.js" /* webpackChunkName: "component---src-pages-roles-artist-index-js" */),
  "component---src-pages-roles-artist-new-js": () => import("./../../../src/pages/roles/artist/new.js" /* webpackChunkName: "component---src-pages-roles-artist-new-js" */),
  "component---src-pages-roles-artist-portfolio-js": () => import("./../../../src/pages/roles/artist/portfolio.js" /* webpackChunkName: "component---src-pages-roles-artist-portfolio-js" */),
  "component---src-pages-roles-artist-settings-js": () => import("./../../../src/pages/roles/artist/settings.js" /* webpackChunkName: "component---src-pages-roles-artist-settings-js" */),
  "component---src-pages-roles-collector-collection-js": () => import("./../../../src/pages/roles/collector/collection.js" /* webpackChunkName: "component---src-pages-roles-collector-collection-js" */),
  "component---src-pages-roles-collector-index-js": () => import("./../../../src/pages/roles/collector/index.js" /* webpackChunkName: "component---src-pages-roles-collector-index-js" */),
  "component---src-pages-roles-collector-new-js": () => import("./../../../src/pages/roles/collector/new.js" /* webpackChunkName: "component---src-pages-roles-collector-new-js" */),
  "component---src-pages-roles-collector-settings-js": () => import("./../../../src/pages/roles/collector/settings.js" /* webpackChunkName: "component---src-pages-roles-collector-settings-js" */),
  "component---src-pages-roles-index-js": () => import("./../../../src/pages/roles/index.js" /* webpackChunkName: "component---src-pages-roles-index-js" */),
  "component---src-pages-roles-printhouse-artists-js": () => import("./../../../src/pages/roles/printhouse/artists.js" /* webpackChunkName: "component---src-pages-roles-printhouse-artists-js" */),
  "component---src-pages-roles-printhouse-config-js": () => import("./../../../src/pages/roles/printhouse/config.js" /* webpackChunkName: "component---src-pages-roles-printhouse-config-js" */),
  "component---src-pages-roles-printhouse-index-js": () => import("./../../../src/pages/roles/printhouse/index.js" /* webpackChunkName: "component---src-pages-roles-printhouse-index-js" */),
  "component---src-pages-roles-printhouse-new-js": () => import("./../../../src/pages/roles/printhouse/new.js" /* webpackChunkName: "component---src-pages-roles-printhouse-new-js" */),
  "component---src-pages-roles-printhouse-newart-js": () => import("./../../../src/pages/roles/printhouse/newart.js" /* webpackChunkName: "component---src-pages-roles-printhouse-newart-js" */),
  "component---src-pages-roles-printhouse-newprinter-js": () => import("./../../../src/pages/roles/printhouse/newprinter.js" /* webpackChunkName: "component---src-pages-roles-printhouse-newprinter-js" */),
  "component---src-pages-roles-printhouse-printers-js": () => import("./../../../src/pages/roles/printhouse/printers.js" /* webpackChunkName: "component---src-pages-roles-printhouse-printers-js" */),
  "component---src-pages-roles-printhouse-queue-js": () => import("./../../../src/pages/roles/printhouse/queue.js" /* webpackChunkName: "component---src-pages-roles-printhouse-queue-js" */),
  "component---src-pages-roles-printhouse-settings-js": () => import("./../../../src/pages/roles/printhouse/settings.js" /* webpackChunkName: "component---src-pages-roles-printhouse-settings-js" */),
  "component---src-pages-roles-printhouse-stagedart-js": () => import("./../../../src/pages/roles/printhouse/stagedart.js" /* webpackChunkName: "component---src-pages-roles-printhouse-stagedart-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-vault-js": () => import("./../../../src/pages/vault.js" /* webpackChunkName: "component---src-pages-vault-js" */)
}

